<template>
  <div>
    <b-alert
      class="w-100 mb-3"
      v-if="!!listSchoolStore.itemsErrorMessage"
      dismissible
      show
      variant="danger"
    >
      <span class="alert-text">{{ listSchoolStore.itemsErrorMessage }}</span>
    </b-alert>

    <div class="row mb-2">
      <LabelCountData
        :count-items-all="listSchoolStore.items.length"
        :count-items="listSchoolStore.items.length"
      />
      <PaginationData
        :current-page="listSchoolStore.page"
        :last-page="listSchoolStore.lastPage"
        @change-nextpage="onNextPageStatic"
        @change-prevpage="onPrevPageStatic"
      />
    </div>

    <b-table
      :busy="kioskStore.listSchool.loading"
      :fields="fields"
      :items="kioskStore.listSchool.items"
      :no-border-collapse="true"
      :sticky-header="false"
      class="table-schools"
      hover
      responsive
      show-empty
      striped
    >
      <template #table-busy>
        <div class="text-center text-muted my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #empty>
        <p class="text-center">Data tidak ditemukan.</p>
      </template>

      <template #cell(select)="data">
        <input
          type="checkbox"
          v-model="selectedIds"
          :value="data.item.id"
        />
      </template>

      <template #cell(name)="data">
        <div>
          ({{ data.item.type }})
          {{ data.item.name }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import PaginationData from "@/components/Table/PaginationData.vue";
import LabelCountData from "@/components/Table/LabelCountData.vue";

export default {
  name: "TableSelectSchools",
  components: { LabelCountData, PaginationData },
  props: ["onClose", "selectSchoolIds"],
  data() {
    return {
      selectedIds: [],
      fields: [
        { key: "select", sortable: false, width: "5px" },
        { key: "name", sortable: false, minWidth: "100px" },
      ],
    };
  },
  computed: {
    ...mapState({
      kioskStore: (state) => state.kioskStore.data,
      listSchoolStore: (state) => state.kioskStore.data.listSchool,
    }),
    ...mapGetters(["provinceNameGetters"]),
  },
  methods: {
    ...mapActions("kioskStore", ["fetchListSchools"]),
    ...mapMutations("kioskStore", ["changeKioskListSchools"]),

    onNextPageStatic() {
      this.changeKioskListSchools({
        page: this.listSchoolStore.page + 1
      })
    },
    onPrevPageStatic() {
      this.changeKioskListSchools({
        page: this.listSchoolStore.page - 1
      })
    },
  },
  beforeDestroy() {
    this.$emit("update:SchoolIdSelected", this.selectedIds);
  },
  mounted() {
    if (!this.listSchoolStore.items.length) {
      this.fetchListSchools();
    }
    if (this.selectSchoolIds) {
      this.selectedIds = this.selectSchoolIds;
    }
  },
};
</script>
