<script>
export default {
  name: "MenuTabs",
  props: {
    menuActive: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menu: [
        {
          menu: "Gambar",
          name: "bannerDigitalPage"
        },
        {
          menu: "Video",
          name: "videoDigitalPage"
        }
      ]
    }
  },
  methods: {
    moveTo(item) {
      if (item.menu===this.menuActive) return false;
      this.$router.push({ name: item.name });
    }
  }
}
</script>

<template>
  <b-tabs pills>
    <b-tab
      class="tabs_naungan"
      v-for="item in menu"
      :key="item.name"
      v-bind:active="item.menu===menuActive"
      style="background: #a0dbff !important;"
      :class="{ 'active': item.menu === menuActive, 'coba': true }"
      :title="item.menu" @click="moveTo(item)">
    </b-tab>
  </b-tabs>
</template>

<style lang="css">
.nav-pills .nav-link.active {
  background: #748cec !important;
  color: #FFFFFF;
}
</style>
